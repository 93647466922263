<template>
  <div class="g col-12 c">
    <div class="card">
        <div class="card-body">
            <button class="btn btn-primary btn-sm" @click="send();" v-if="!loading_links">
                <i class="fa fa-paper-plane"></i>
                ارسال الشهادات لاولياء الامور
            </button>
            <button class="btn btn-info btn-sm" v-if="loading_links">
                 <img src="https://app.naqar.net/files/loading.svg" style="width: 30px; height: 30px">
                جاري التجهيز... <div id="doneees"></div>
            </button>
            &nbsp;
            <select v-model="classroom">
                <option value="all">عرض كل الفصول</option>
                <option v-for="o in classrooms" :key="o" :value="o">{{ o }}</option>
            </select>
            &nbsp;
            <span>
                <span style="border: dashed 1px darkred; padding: 10px; padding-right: 20px; padding-left: 20px;">
                    <input type="text" placeholder="اكتب اسم المدير هنا..." id="v3" style="border-radius: 0px; padding: 5px;">&nbsp;
                    <input type="text" placeholder="اكتب اسم الموجه الطلاب هنا..." id="v2" style="border-radius: 0px; padding: 5px;">
                    &nbsp;
                    <button class="btn btn-sm disabled" style="border-radius: 0px" disabled v-if="renderloading">
                        <img src="https://app.naqar.net/files/loading.svg" style="width: 30px; height: 30px">
                        جاري الانشاء... ربما تستغرق دقيقة.
                    </button>
                    <button class="btn btn-primary btn-sm" style="border-radius: 0px" @click="render()" v-if="!renderloading">
                        <i class="fa fa-check-square"></i>
                        انشاء الشهادات الآن
                    </button>
                </span>
            </span>
            <br><br>
            <table id="headerTable" class="table table-hover table-bordered table-sm">
                <h4 id="datex" class="dnone"></h4>
              <thead>
                <th>
                  م
                </th>
                <th>
                  اسم الطالب
                </th>
                <th>
                  رقم الهوية
                </th>
                <th>
                  الفصل
                </th>
                <th>
                   الشهادة
                </th>
              </thead>
              <tbody>
                <template v-for="(student, c) in students">
                  <tr :key="c" v-if="(classroom == 'all' || classroom == student.classroom) && !certs.includes(student.name)">
                      <td>{{ c + 1 }}</td>
                      <td>{{ student.name }}</td>
                      <td>{{ student.number }}</td>
                      <td>{{ student.classroom }}</td>
                      <td>
                        <i class="text-muted" v-if="!linksx[student.number]">
                            قم بانشاء الشهادات من الأعلى اولاً.
                        </i>
                        <div v-if="linksx[student.number]">
                            <a :href='linksx[student.number].blob' target="_blank" class='btn btn-primary btn-sm' style='border-radius: 0px'>
                                <i class='fa fa-external-link'></i>
                                عرض 
                            </a><a :href='linksx[student.number].png' :download='student.name' class='btn btn-success btn-sm sendme ccccc' style='border-radius: 0px'>
                                <i class='fa fa-download'></i>
                                تحميل 
                            </a>
                        </div>
                      </td>
                  </tr>
                </template>
              </tbody>
            </table>
        </div>
    </div><button
      class="dnone"
      id="notjoinstudentsalert" @click="messageChanged()"
      v-b-modal.modal-teachers-lessons-send2ss
    ></button>
    <b-modal id="modal-teachers-lessons-send2ss" size="lg" title="ارسال تنبيه" hide-footer>
      <div class="col-12 text-center g">
            <div class="alert alert-success text-center g" v-if="view_archive">
                <img src="/files/done.gif" style="border-radius: 50%">
                <br>
                <br>
                تم استلام الرسائل بنجاح وجاري ارسالها الآن٫<br>
                يمكنك متابعة الارسال <a :href="`/archive?school_id=${school_id}`" target="_blank"><strong><u>من هنا</u></strong></a>
            </div>
            <template v-if="!loading && !view_archive">
                <button class="btn btn-lg btn-primary" v-if="selected == 'sms' || selected == 'whatsapp-api'" @click="sendNow(selected)">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
                <div class="col-12 text-center alert alert-success" v-if="selected == 'whatsapp'" id='remove-me-wa'>
                    <h4>
                        لتتمكن من ارسائل رسائل الواتسآب، يجب عليك تحميل أداة الخدمات الاضافية من سوق كروم.
                        <br><br>
                        <a href="https://bit.ly/3yAC50a" target="_blank" class="btn btn-success">
                            <i class="fa fa-download"></i>
                            إضغط هنا لتثبيت الاداة
                        </a>
                        <br>
                        وبعد تثبيتها قم بإعادة تحديث هذه الصفحة.
                    </h4>
                </div>
                <button class="btn btn-lg btn-primary" v-if="selected == 'whatsapp'" @click="sendNow(selected)" id="brmja-send">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
            </template>
            <template v-if="loading && !view_archive">
                <button class="btn btn-lg btn-primary disabled">
                    جاري الإرسال...
                </button>
            </template>
        </div>
        <div class="form-group">
          <label for="">محتوى الرسالة</label>
          <textarea class="form-control" v-model="message" @keyup="messageChanged()" rows="5"></textarea>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-sm table-hover" id="brmja-messages-table">
                <thead>
                    <th>
                        الجوال
                    </th>
                    <th>
                        الطالب
                    </th>
                    <th>
                        الرسالة
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(teacher, c) in teachers" :key="c">
                        <td>{{ teacher.phone }}</td>
                        <td>{{ teacher.name }}</td>
                        <td><textarea class='form-control msg' readonly v-model="teacher.message"></textarea></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
    <div id="result" class="dnone"></div>
  </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            renderloading: false,
            school_name: decodeURI(window.location.href.split("school_name=")[1].split("&")[0]),
            school_id: window.location.href.split("school_id=")[1].split("&")[0],
            message: `شهادة شكر وتقدير لابنكم {name} لانضباطه المدرسي.[file]{link_url}[/file]`,
            students: [],
            loading: false,
            view_archive: false,
            classrooms: [],
            selected_classroom: "all",
            selected: "",
            certs: [],
            show: 'all',
            length: 0,
            classroom: 'all',
            teachers: [],
            days: {
                0: "الاحد",
                1: "الاثنين",
                2: "الثلاثاء",
                3: "الاربعاء",
                4: "الخميس",
                5: "الجمعة",
                6: "السبت"
            },
            linksx: {},
            original: [],
            loading_links: false,
            links: []
        }
    },
    created(){
      var g = this;
      var school_id = g.school_id
      if(school_id == "undefined" || !school_id){
        alert("برجاء الدخول لحسابك في منصة مدرستي واختيار المدرسة اولاً للتعرف على الحساب.");
        window.location = "https://schools.madrasati.sa/"
      }
      $.post(api + '/api/subscribe/certs', {
        school_id: school_id
      }).then(function(){})
      setTimeout(() => {
        $("#datex").html(new Date().toISOString().split('T')[0])
        $("body").append(`
          <textarea class='get-local-storage' name='m_students_${school_id}'></textarea>
        `)
        var i1 = setInterval(() => {
            if($(`.get-local-storage-value[name='m_students_${school_id}']`).length){
              clearInterval(i1)
              if($(`.get-local-storage-value[name='m_students_${school_id}']`).val() == "undefined" || $(`.get-local-storage-value[name='m_students_${school_id}']`).val() == "null" || !$(`.get-local-storage-value[name='m_students_${school_id}']`).val()){
                alert("برجاء استيراد الطلاب للاداة من خلال نظام نور في منصة مدرستي اولاً.\n\nقم بالذهاب لمنصة مدرستي -> اضغط على اداة المساعد الذكي -> خدمات الطلاب -> تحديث بيانات الطلاب من نور.")
              }
              try{
                g.students = JSON.parse($(`.get-local-storage-value[name='m_students_${school_id}']`).val()).map(function(f){
                    f.days = []
                    return f;
                })
              }catch{
                alert("برجاء استيراد الطلاب للاداة من خلال نظام نور في منصة مدرستي اولاً.\n\nقم بالذهاب لمنصة مدرستي -> اضغط على اداة المساعد الذكي -> خدمات الطلاب -> تحديث بيانات الطلاب من نور.")
              }
              g.original = JSON.parse(JSON.stringify(g.students))
              g.students.forEach(element => {
                if(!g.classrooms.includes(element.classroom)){
                  g.classrooms.push(element.classroom)
                }
              })
              $("body").append(`
                <textarea class='get-local-storage' name='certs'></textarea>
                `)
                var ix = setInterval(() => {
                    if($(`.get-local-storage-value[name='certs']`).length){
                    clearInterval(ix)
                        g.certs = JSON.parse($(`.get-local-storage-value[name='certs']`).val())
                    }
                }, 10);
            }
        }, 10);
      }, 500);
    },
    methods: {
        print(){
            var divToPrint=document.getElementById("headerTable");
            var newWin= window.open("");
            newWin.document.write(divToPrint.outerHTML + `<style>
                body{
                    direction: rtl;
                    text-align: right
                }
                thead{
                    background: #eee;
                }
                table, td, th {
                border: 1px solid;
                }
                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }
                table {
                width: 100%;
                border-collapse: collapse;
                }
                button{
                    display:none
                }
                </style>`);
            newWin.document.close();
            newWin.print();
            newWin.close();
        },
        send(){
            var g = this;
            if(!Object.keys(g.linksx).length && !window.location.href.includes("dev")){
                alert("قم بانشاء الشهادات اولاً")
                return false;
            }
            if(!g.links.length){
                g.loading_links = true;
                var arr = [], before = JSON.parse(JSON.stringify(g.students));
                function ll(){
                    if(!before.length){
                        g.students = JSON.parse(JSON.stringify(arr));
                        $("#c-subscribe-ended").click()
                        $("#after-send-settings-click").val("#notjoinstudentsalert")
                    }else{
                        var a = before[0];
                        if((g.classroom == 'all' || g.classroom == a.classroom) && !g.certs.includes(a.name) && g.linksx[a.number]){
                            $.post("https://naqar.net/base64-to-image", {
                                data: g.linksx[a.number].png
                            }).then(function(r){
                                a.link = r;
                                arr.push(a)
                                $("#doneees").html(`(تم ${((arr.length/g.students.length)*100).toFixed(0)}%)`)
                                before.shift()
                                ll()
                            }).fail(function(){
                                ll()
                            })
                        }else{
                            before.shift()
                            ll()
                        }
                    }
                }
                ll()
            }else{
                $("#c-subscribe-ended").click()
                $("#after-send-settings-click").val("#notjoinstudentsalert")
            }
        },
        messageChanged(){
            var g = this, c = [];
            var d = new Date();
            if(localStorage.getItem('send')){
                g.selected = JSON.parse(localStorage.getItem('send')).selected
            }
            g.students.forEach(function(a){
                if((g.classroom == 'all' || g.classroom == a.classroom) && !g.certs.includes(a.name)){
                    a.message = g.message?.replace("{name}", a.name)?.replace("{link_url}", 'https://naqar.net/' + a.link)
                    if(g.selected != 'whatsapp-api'){
                        a.message = a.message.replace("[file]", " ").replace("[/file]", " ")
                    }
                    c.push(a)
                }
            })
            g.teachers = c
        },
        sendNow(method){
            var g = this;
            g.loading = true
            $.post(api + '/api/messages/send', {
                school_id: localStorage.getItem('school_id'),
                arr: JSON.stringify(g.teachers),
                method: method,
                settings: localStorage.getItem('send'),
                type: 'teachers'
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.view_archive = true
                }else{
                    alert(response.response)
                }
            })
            .fail(function(){
                g.loading = false
                alert('حدث خطأ')
            })
        },
        render(){
            var g = this;
            this.renderloading = true;
            if(!g.original.length){
                g.linksx = JSON.parse(JSON.stringify(g.linksx))
                g.renderloading = false
                return false;
            }
            var a = g.original[0];
            if((g.classroom == 'all' || g.classroom == a.classroom) && !g.certs.includes(a.name)){
                $("#result").html(`<canvas id="myCanvas"></canvas>
                <img style='display:none;'id='img'/>
                `)
                var canvas = document.getElementById('myCanvas');
                var ctx = canvas.getContext('2d');
                var imageObj = document.getElementById('img');
                imageObj.onload = function(e) {
                    ctx.canvas.width = imageObj.width;
                    ctx.canvas.height = imageObj.height;
                    ctx.drawImage(imageObj, 0, 0,imageObj.width,imageObj.height);
                    ctx.textBaseline = 'middle';
                    ctx.textAlign = "center";
                        ctx.font = "30pt Calibri";
                        ctx.fillText(`تقدم ادارة مدرسة ${g.school_name} بالشكر والتقدير`, 900, 400);
                        ctx.font = "50pt Calibri";
                        ctx.fillStyle = "green";
                        ctx.fillText(a.name, 900, 500);
                        ctx.fillStyle = "black";
                        ctx.font = "30pt Calibri";
                        ctx.fillText($("#v2").val(), 1250, 1000);
                        ctx.fillText($("#v3").val(), 480, 1000);
                    canvas.toBlob(function(blob){
                        g.linksx[a.number] = {
                            blob: URL.createObjectURL(blob),
                            png: canvas.toDataURL("png")
                        }
                        g.original.shift()
                        g.render()
                    });
                };
                imageObj.src = '/c.png';
            }else{
                g.original.shift()
                g.render()
            }
        }
    }
}
</script>

<style>

</style>